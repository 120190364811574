import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';
import { Identity } from 'client-generator/generated-clients/api-clients';
import { SET_DISCLAIMER_ACCEPTED } from '@/core/store/types/action-types';
import { PermissionType, Plugins } from '@capacitor/core';

@Options({
    name: 'OpenDisclaimer',
    computed: {
        ...mapState(['profile']),
    },
})
export default class OpenDisclaimer extends Vue {
    profile!: Identity;
    missingPermissions = false;

    get userNameSurname() {
        return `${this.profile?.firstName} ${this.profile?.lastName}`;
    }

    acceptDisclaimer() {
        this.$store.dispatch(SET_DISCLAIMER_ACCEPTED, true);

        this.$router.replace({
            name: 'page-timeline',
        });
    }

    async beforeCreate() {
        const { Permissions } = Plugins;
        const cameraPermissionGranted = (await Permissions.query({ name: PermissionType.Camera })).state === 'granted';
        const microphonePermissionGranted = (await Permissions.query({ name: PermissionType.Microphone })).state === 'granted';
        this.missingPermissions = !(cameraPermissionGranted && microphonePermissionGranted);
    }
}
