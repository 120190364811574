<template>
    <ion-page>
        <ion-content padding class="open-disclaimer">
            <div
                class="open-disclaimer__container d-flex flex-column ion-justify-content-center h-100"
            >
                <div class="my-auto mx-2">
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title class="text-center">{{
                                $t("openDisclaimer.title")
                            }}</ion-card-title>
                        </ion-card-header>
                        <ion-card-content class="mt-3 text-center">
                            <p>
                                {{ $t("openDisclaimer.descriptionFirst") }}
                            </p>
                            <p>
                                {{ $t("openDisclaimer.descriptionSecond") }}
                            </p>
                            <div
                                class="alert alert-danger"
                                role="alert"
                                v-if="missingPermissions"
                            >
                                {{ $t("missingPermissionsWarning") }}
                            </div>
                            <ion-button
                                color="primary"
                                expand="block"
                                class="ok-btn mx-0 mt-4"
                                @click="acceptDisclaimer"
                                >{{
                                    $t("openDisclaimer.okBtnLabel", {
                                        nameSurname: userNameSurname,
                                    })
                                }}</ion-button
                            >
                            <div
                                class="mt-3"
                                v-html="
                                    $t('openDisclaimer.lastMessage', {
                                        nameSurname: userNameSurname,
                                    })
                                "
                            />
                        </ion-card-content>
                    </ion-card>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./OpenDisclaimer.ts"></script>

<style lang="scss" src="./OpenDisclaimer.scss"></style>
